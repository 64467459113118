import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  selectYesOrNo: string;
  checkIfReqIsConfirmed: string;
  userCellNumberForYesCase: string;
  isUserCellNumberValidForYesCase: boolean;

  recipientCellNumberForNoCase: string;
  isRecipientCellNumberValidForNoCase: boolean;

  yourCellNumberForNoCase: string;
  isYourCellNumberValidForNoCase: boolean;
}

interface SS {
  id: any;
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      selectYesOrNo: "None",
      checkIfReqIsConfirmed: "initial",
      userCellNumberForYesCase: "",
      isUserCellNumberValidForYesCase: true,

      recipientCellNumberForNoCase: "",
      isRecipientCellNumberValidForNoCase: true,

      yourCellNumberForNoCase: "",
      isYourCellNumberValidForNoCase: true,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  isNumber(value: number) {
    return !isNaN(parseFloat(String(value))) && isFinite(value);
  }

  handleYesOrNo(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      selectYesOrNo: event.target.value
    });
  }

  handleConfirmRequest() {
    this.setState({
      checkIfReqIsConfirmed: "fail"
    });
  }

  handleUserCellNumberForYesCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isUserCellNumberValidForYesCase: (isValid && checkLength),
      userCellNumberForYesCase: event.target.value,
    })
  }

  handleRecipientCellNumberForNoCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isRecipientCellNumberValidForNoCase: (isValid && checkLength),
      recipientCellNumberForNoCase: event.target.value,
    })
  }

  handleYourCellNumberForNoCase(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    const checkLength = event.target.value.length === 10;
    this.setState({
      isYourCellNumberValidForNoCase: (isValid && checkLength),
      yourCellNumberForNoCase: event.target.value,
    })
  }

  cellNumberHelperText1() {
    if (!this.state.userCellNumberForYesCase || this.state.isUserCellNumberValidForYesCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  cellNumberHelperText2() {
    if (!this.state.recipientCellNumberForNoCase || this.state.isRecipientCellNumberValidForNoCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  cellNumberHelperText3() {
    if (!this.state.yourCellNumberForNoCase || this.state.isYourCellNumberValidForNoCase) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  goTermsConditions = () => {
    localStorage.setItem("termsAndPolicy", "2");
    this.handleNavigation("TermsConditions");
  }

  goToHomeScreenFromSuccess() {
    this.handleNavigation("AdvancedSearch");
  }

  goToHomeScreenFromFailure() {
    this.handleNavigation("AdvancedSearch");
  }

  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

}

// Customizable Area End
