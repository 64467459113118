import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { adamImage, eyeIcon, jennyImage, logo, searchIcon, userIcon } from "./assets"
import { styled } from "@mui/material/styles";
import BookingRequest  from "../../rolesandpermissions2/src/Rolesandpermissions2.web"
import Settings2 from "../../settings2/src/Settings2.web";

import AdminDashboardController, {
    Props,
} from "./AdminDashboardController";

const MainContainer = styled(Box)({
    width: "100%",
    overflowX: "hidden",
    "@media (max-width: 600px)": {

    },
    "& .navBox": {
        height: "74px",
        padding: "0px 124px 0px 84px",
        border: "1px solid #C1C7CD",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 768px)": {
            padding: "0px",
            justifyContent: "space-around",
        },
    },
    "& .nameBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "213px",
        width: "100%",
        borderLeft: "1px solid #D6DDEB",
        height: "48px",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },

    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .adminText": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28.8px",
        color: "#25324B",
        paddingTop: "65px",
        paddingBottom: "24px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .alignContainer": {
       paddingLeft: "108px",
       width:"100%",
       maxWidth:"1178px",
       "@media (max-width: 768px)": {
        paddingLeft: "0px"
    },
    },
    
    "& .tabsText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#7C8493"
    },
    "& .tabsAlignment": {
         paddingLeft: "108px",
         width:"100%",
         maxWidth:"1178px",
         borderBottom: "1px solid #D6DDEB",
         height:"37px",
    },
    "& .searchBox": {
        width: "100%",
        borderRadius:"10px",
        fontFamily: "Manrope",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "20.49px",
        color:"#8BA3CB"
    },
    "& .boxAlign": {
        maxWidth: "624px",
        width:"100%",
        borderRadius: "10px",
        backgroundColor: "#F5F7FA",
    },
    "& .requestText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        color: "#25324B",
        paddingBottom: "12px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .serviceText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "21.86px",
        letterSpacing: "0.01em",
        color: "#5E5C5C"
    },
    "& .customBtn": {
        maxWidth: "109px",
        width:"100%",
        height: "30px",
        borderRadius: "10px",
        border: "1px solid #5E70DD",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px"
    },
    "& .seeMore": {
        color: "#5E70DD",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "21px"
    },
    "& .tectText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        letterSpacing: "0.01em",
        color: "#000000"
    },
    "& .tableBtn": {
        maxWidth: "203px",
        width:"100%",
        height: "43px",
        borderRadius: "10px", 
        backgroundColor: "#5E70DD !important",
        color: "#FFFFFF",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "50%"
        },
    },
    "& .teenText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16.8px",
    },
    "& .secondBox": {
        maxWidth: "528px",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#F5F7FA"
    },
    "& .adamText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24.59px",
        color: "#0D0D0D"
    },
    "& .adamContent": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#A0A0A0"
    },
    "& .tabsBox": {
        width:"100%",
        display:"flex",
        gap:"40px",
        flexWrap: "wrap",
        borderBottom: "1px solid #D6DDEB",
        "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "center",
        gap: "20px"
        },
    },
    "& .managePadding": {
       padding: "0 44px 64px 33px",
       "@media (max-width: 768px)": {
        padding: "0 10px 64px 10px",
       }
    },
    "& .manageMessge": {
        display:"flex",
        flexWrap:"wrap",
        alignItems:"center",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            padding: "10px",
           }
    }
})


export default class AdminDashboard extends AdminDashboardController {
    constructor(props: Props) {
        super(props);

    }

    renderSettingPage = (currentTab: number) => {
        return <>
         {(currentTab == 4) && <Settings2 navigation={this.props.navigation} id={"1"} />}</>
    }
    render() {
        let { tabs } = this.state

            let tableData = this.state.bookingRequestData?.requests || []
            let tableData2 = [
                {"name": "Jenny Wilson", "tech": "Tech Help", "btnName": "Search with Student", "content":"Remember that concert last y..."},
                {"name": "Jenny Wilson", "tech": "Tech Help", "btnName": "Search with Student", "content":"Remember that concert last y..."}
            ]

        return (
            <MainContainer>
                <Box className="navBox">
                    <img src={logo.default} />
                    <img src={userIcon.default} className="userIcon" />
                    <Box className="nameBox">
                        <Typography className="nameText">{this.state.userName?.fullName}</Typography>
                    </Box>
                </Box>

                <Box className="alignContainer">
                    <Typography className="adminText">Admin Role</Typography>
                     <Box className="tabsBox">
                        {tabs.map((item, index)=> 
                        <Box>
                            <Typography className="tabsText" style={{color:`${(index == this.state.currentTab) ? "#25324B": "#7C8493"}`}}
                            data-test-id = "currentTabId"
                            onClick={()=> this.setState({currentTab: index})}
                            >{item.label}</Typography>
                    {(index == this.state.currentTab) ? <Box style={{ backgroundColor: "#4640DE", height:"4px", borderTopLeftRadius: "10px",  borderTopRightRadius: "10px", marginTop: "8px"}}>
                    </Box>: null}
                        </Box>
                        )}
                    </Box>
                    {(this.state.currentTab == 0) && <Box>

                <Box style={{ padding: "28px 0px"}}>
                    <TextField 
                    type="text"
                    placeholder="Search for Students/Seniors"
                    className="searchBox"
                    sx={{
                        height: "50px",
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        '& .MuiOutlinedInput-root': {
                            height: "50px",
                            '& fieldset': {
                                border: "none",
                            }
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={searchIcon.default} />
                            </InputAdornment>
                        )
                    }}
                    />
                </Box>

                    <Box style={{ display:"flex", justifyContent:"space-between", flexWrap:"wrap", width:"100%"}}>
                <Box style={{maxWidth:"625px", width:"100%"}}>
                    <Typography className="requestText">Booking Requests</Typography>
                    <Box className="boxAlign">
                        <Box className="managePadding">
                        <Box  style={{display:"flex", justifyContent:"space-between", alignItems:"center",  height:"76px"}}>
                            <Typography className="serviceText">Senior</Typography>
                            <Typography className="serviceText">Service</Typography>
                            <Box className="customBtn">
                                <img src={eyeIcon.default} />
                                <Typography className="seeMore">See More</Typography>
                            </Box>
                        </Box>
                        <Box style={{width: "100%", border:"1px solid #DADADA"}}></Box>

                        <Box style={{display:"flex", flexDirection: "column",}}>
                            {
                                tableData.map((row: any) => 
                            <Box style={{display:"flex", justifyContent:"space-between", alignItems: "center", paddingTop:"16px"}}>
                            <Box style={{display:"flex", gap:"12px", alignItems:"center"}}>
                                <img src={jennyImage.default} />
                                <Typography className="tectText" style={{fontWeight: 600 }}>{row.recipient_first_name} {row.recipient_last_name}</Typography>
                            </Box>
                            <Typography className="tectText">{row.service_name}</Typography>
                            <Button className="tableBtn">Search with Student</Button>
                            </Box>
                                )

                            }
                        </Box>
                        </Box>

                    </Box>
                </Box>

                <Box style={{ maxWidth: "528px",  width: "100%"}}>
                    <Box className="manageMessge">
                        <Typography className="requestText">Messages</Typography>
                        <Box style={{ display:"flex",alignItems:"center", gap:"16px" }}>
                            <Typography className="teenText" style={{ color: `${!this.state.selected ? "#25324B" : "#7C8493"  }`}}
                            onClick={this.handleSelected}
                            >Teens</Typography>
                            <Typography className="teenText" style={{ color: `${this.state.selected ? "#25324B" : "#7C8493"}`}}
                            onClick={this.handleSelected}
                            >Seniors</Typography>
                        </Box>
                    </Box>
                    <Box className="secondBox">
                        <Box style={{padding: "47.03px 22.97px 30px"}}>
                <Box style={{display:"flex", flexDirection: "column", gap:"16px"}}>{
                    tableData2.map((item) =>
                    <Box style={{display:"flex", alignItems:"center", gap:"12px"}}>
                    <img src={adamImage.default} />
                    <Box>
                        <Typography className="adamText">{item.name}</Typography>
                        <Typography className="adamContent">{item.content}</Typography>
                    </Box>
                    </Box>
                    )}
                </Box>
                </Box>

                    </Box>

                </Box>

                </Box>
                </Box>}

                {(this.state.currentTab == 2) &&
                      <BookingRequest navigation={this.props.navigation} id={"1"} /> }
                {this.renderSettingPage(this.state.currentTab)}
                </Box>
            </MainContainer>
        )
    }
}
// Customizable Area End