export const logo = require("../assets/Logo.svg")
export const notify = require("../assets/notify.svg")
export const msgIcon = require("../assets/msgIcon.svg")
export const topImage = require("../assets/topImage.svg")

export const petIcon = require("../assets/petIcon.svg")
export const indoorIcon = require("../assets/indoorIcon.svg")
export const outdoorIcon = require("../assets/outdoorIcon.svg")
export const techIcon = require("../assets/techIcon.svg")
export const orgIcon = require("../assets/orgIcon.svg")
export const runIcon = require("../assets/runIcon.svg")
export const visitIcon = require("../assets/visitIcon.svg")
export const cropImage = require("../assets/cropImage.svg")
export const tickIcon = require("../assets/tickIcon.svg")

export const paper1 = require("../assets/paper1.svg")
export const paper2 = require("../assets/paper2.svg")
export const paper3 = require("../assets/paper3.svg")
export const paper4 = require("../assets/paper4.svg")
export const paper5 = require("../assets/paper5.svg")
export const paper6 = require("../assets/paper6.svg")
export const paper7 = require("../assets/paper7.svg")
export const timer = require("../assets/timer.svg")
export const mapIcon = require("../assets/map.svg")
export const calIcon = require("../assets/calendar.svg")
export const estimate = require("../assets/estimate.svg")
export const gradient = require("../assets/gradient.svg")

export const capIcon = require("../assets/capIcon.svg")
export const teleIcon = require("../assets/teleIcon.svg")
export const msg2Icon = require("../assets/msg2Icon.svg")
export const star = require("../assets/star.svg")
export const userIcon = require("../assets/userIcon.svg") 
export const calenderIcon = require("../assets/calenderIcon.svg") 
export const successCheckIcon = require("../assets/successCheckIcon.svg") 
export const handShakeIcon = require("../assets/handShakeIcon.svg") 
export const brightnessIcon = require("../assets/brightnessIcon.svg") 

export const activeCat    = require("../assets/activeCat.svg")
export const activeHanger = require("../assets/activeHanger.svg")
export const activeIndoor = require("../assets/activeIndoor.svg")
export const activeMsg    = require("../assets/activeMsg.svg")
export const activeOutdoor = require("../assets/activeOutdoor.svg")
export const activeVisit   = require("../assets/activeVisit.svg")
export const deactiveTech  = require("../assets/activeTech.svg") 
export const successIcon  = require("../assets/successIcon.svg")
export const chatIcon  = require("../assets/chatIcon.svg")